import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import AuthAdminRoute from "../hocs/AuthAdminRoute";
import AuthRoute from "../hocs/AuthRoute";
import NonAuthRoute from "../hocs/NonAuthRoute";
import AuthProvider from "../hocs/AuthProviver";
import LoadingSpinner from "../components/common/LoadingSpinner";
const AdminDocuments = lazy(() => import("../containers/AdminDocuments"));
const AdminMyDetails = lazy(() => import("../containers/AdminMyDetails"));
const AdminSettings = lazy(() => import("../containers/AdminSettings"));
const AdminFavourites = lazy(() => import("../containers/AdminFavourites"));
const Apartment = lazy(() => import("../containers/Apartments"));
const DetailFloor = lazy(() => import("../containers/Apartments/FloorView/DetailFloor"));
const DetailProduct = lazy(() => import("../containers/DetailProduct"));
const Home = lazy(() => import("../containers/Home"));
const Location = lazy(() => import("../containers/Location"));
const Login = lazy(() => import("../containers/Login"));
const ForgotPassword = lazy(() => import("../containers/ForgotPassword"));
const Design = lazy(() => import("../containers/Design"));
const Media = lazy(() => import("../containers/Media"));
const Team = lazy(() => import("../containers/Team"));
const Lifestyle = lazy(() => import("../containers/Lifestyle"));
const LifestyleDetail = lazy(() => import("../containers/LifestyleDetail"));

const Routes = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <BrowserRouter>
                <AuthProvider>
                    <Switch>
                        <AuthAdminRoute path="/admin/documents" component={AdminDocuments} exact />
                        <AuthAdminRoute path="/admin/my-details" component={AdminMyDetails} exact />
                        <AuthAdminRoute path="/admin/settings" component={AdminSettings} exact />
                        <AuthAdminRoute path="/admin/favourites" component={AdminFavourites} exact />
                        <NonAuthRoute path="/home" component={Home} exact />
                        <NonAuthRoute path="/" component={Login} exact />
                        <NonAuthRoute path="/forgot-password" component={ForgotPassword} exact />
                        <AuthRoute path="/apartments" component={Apartment} exact />
                        <AuthRoute path="/apartments/:id/level/:idBuilding" component={DetailFloor} exact />
                        <AuthRoute path="/apartments/:id/detail" component={DetailProduct} exact />
                        <AuthRoute exact path="/location" component={Location} />
                        <AuthRoute exact path="/lifestyle" component={Lifestyle} />
                        <AuthRoute path="/design" component={Design} exact />
                        <AuthRoute path="/team" component={Team} exact />
                        <AuthRoute path="/library" component={Media} exact />
                        <AuthRoute exact path="/lifestyle/:id" component={LifestyleDetail} />
                    </Switch>
                </AuthProvider>
            </BrowserRouter>
        </Suspense>
    );
};

export default Routes;
