import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import Layout from "../components/Layout";
import LoadingSpinner from "../components/common/LoadingSpinner";

function NonAuthRoute({ component: Component, ...rest }) {
    const authentication = useSelector((state) => state.user.authentication);
    const loading = useSelector((state) => state.user.loading);
    const location = useLocation();

    if (loading) return <LoadingSpinner />;

    if (authentication && location.pathname !== "/home") {
        return <Redirect to="/home" />;
    }

    return (
        <Route {...rest}>
            <Layout>
                <Component />
            </Layout>
        </Route>
    );
}

export default NonAuthRoute;
