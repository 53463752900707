import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import "./App.css";
import Routes from "./routes";
import i18n from "./services/i18n";
import store from "./stores";
import theme from "./styles/theme";

import { useRequest } from "ahooks";
import { setConfig, setCookie, getCookie, createUid, detectBrowser } from "igjs/src/custom";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect } from "react";
import { configApi } from "./services/api/auth";
import { insertLog } from "./services/api/tracking";

var chSignalR = null;
var chTabActive = true;
var chActionTabs = [];
var chSessionCookie = 1; // minute
var chCountdown = chSessionCookie * 60; // second
var chZLogUi = false;
var chTimer;
var chCounter = 0;

const App = () => {
    useRequest(configApi, {
        onSuccess: async (rsp) => {
            let d = rsp.data;
            setConfig(d);

            chSessionCookie = d.sessionCookie;
            chZLogUi = d.zLogUi;

            let port = "";
            if (d.debugMode) {
                setCookie("ClientId", createUid());
                setCookie("SessionId", createUid());
            } else {
                port = ":8097";
            }
            let url = `${d.msUrl}${port}/CounterHub`;

            chSignalR = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();

            chSignalR
                .start()
                .then((result) => {
                    let msg = "Starting counter online...";
                    zlog(msg);

                    chSignalR.on("ReceiveMessage", function (user, message) {
                        msg = `${user}: ${message}\n`;
                        zlog(msg);
                    });

                    chSignalR.on("DoItNow", function (id, method, data) {
                        msg = `${id}: ${method}, data: ${data}\n`;
                        zlog(msg);
                    });
                })
                .catch(function (err) {
                    return console.error(err.toString());
                });

            url = new URL(window.location.href);
            chZLogUi = url.searchParams.get("zlog") === "" || chZLogUi;

            setCookie("TabId", createUid());
            insertLog(window.location.pathname);
        },
    });

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);

        window.addEventListener("mousemove", onActive);
        window.addEventListener("keypress", onActive);

        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);

            window.removeEventListener("mousemove", onActive);
            window.removeEventListener("keypress", onActive);
        };
    }, []);

    clearInterval(chTimer);
    chTimer = setInterval(function () {
        // Run every 3 seconds send data to server
        if (chCounter % 3 === 0) {
            invokeHub("UpdateTab");
        }

        // Run every 10 seconds check user idle
        if (chCounter % 10 === 0) {
            chCounter = 0;

            if (chTabActive) {
                chTabActive = false;
            }
        }
        chCounter++;

        chCountdown--;
        if (chCountdown <= 0) {
            clearInterval(chTimer);
            invokeHub("CloseSession");
        }

        zlog(`chTabActive ${chTabActive} chCountdown = ${toHhMmSs(chCountdown)}`);
        updateActionTab(chTabActive);
    }, 1000); // run every 1 second

    /**
     * Reset countdown
     */
    const resetCountdown = () => {
        chCountdown = chSessionCookie * 60;
        setActionTab(true);
    };

    /**
     * Console log
     * @param {*} msg Message
     */
    const zlog = (msg) => {
        if (chZLogUi) {
            console.log(msg);
        }
    };

    /**
     * Invoke hub
     * @param {*} name Method name
     */
    const invokeHub = (name) => {
        if (chSignalR == null) {
            return;
        }

        let at = getActionTab(); // current data
        if (at === undefined) {
            return;
        }
        zlog(at);

        let t = {
            clientId: getCookie("ClientId"),
            sessionId: getCookie("SessionId"),
            sessionExpired: name === "CloseSession",
            sessionClosed: name === "CloseTab",
            tabs: [at],
            screenSize: null,
            mouseInfos: null,
            browser: detectBrowser(),
        };

        let data = JSON.stringify(t);
        chSignalR
            .invoke(name, data)
            .then(function () {
                zlog("Called " + name + "...");
            })
            .catch(function (err) {
                console.log(err.message);
            });
    };

    /**
     * Update data action tab
     * @param {*} active Active = true or inactive = false
     */
    const updateActionTab = (active) => {
        let at = getActionTab();
        if (at === undefined) {
            at = new ActionTab(window.location.pathname);
            setCookie("TabId", at.uid);
            insertLog(window.location.pathname);

            if (active) {
                calcActive(at);
            } else {
                calcInactive(at);
            }

            chActionTabs.push(at);
        } else {
            if (active) {
                calcActive(at);
            } else {
                calcInactive(at);
            }
        }
    };

    /**
     * Set action tab
     * @param {*} active Active = true or inactive = false
     */
    const setActionTab = (active) => {
        let d = new Date();
        let at = getActionTab();
        if (at === undefined) {
            at = new ActionTab(window.location.pathname);
            setCookie("TabId", at.uid);
            insertLog(window.location.pathname);

            if (active) {
                at.lastActive = d;
            } else {
                at.lastInactive = d;
            }

            chActionTabs.push(at);
        } else {
            if (active) {
                at.lastActive = d;
            } else {
                at.lastInactive = d;
            }
        }

        chTabActive = active;
    };

    /**
     * Calculate time active
     * @param {any} o Object
     */
    const calcActive = (o) => {
        o.timeActive += 1;
        o.lastActive = new Date();
        chCountdown = chSessionCookie * 60; // reset countdown (second)

        let v = getCookie("SessionId");
        setCookie("SessionId", v, chSessionCookie);

        v = getCookie("TabId");
        setCookie("TabId", v, chSessionCookie);
    };

    /**
     * Calculate time inactive
     * @param {any} o Object
     */
    const calcInactive = (o) => {
        o.timeInactive += 1;
        o.lastInactive = new Date();

        if (o.startInactive == null) {
            o.startInactive = o.lastInactive;
        }
    };

    /**
     * Get action tab
     */
    const getActionTab = () => {
        // Find path name exists
        let path = window.location.pathname;
        let res = chActionTabs.find((p) => p.path === path);
        return res;
    };

    /**
     * User has switched back to the tab
     */
    const onFocus = () => {
        zlog("Tab is in focus");
        resetCountdown();
    };

    /**
     * User has switched away from the tab
     */
    const onBlur = () => {
        zlog("Tab is blurred");
        setActionTab(false);
    };

    /**
     * User has moved mouse or pressed key
     */
    const onActive = () => {
        zlog("Tab is activated");
        resetCountdown();
    };

    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={theme}>
                    <Routes />
                    <ToastContainer />
                </ThemeProvider>
            </I18nextProvider>
        </Provider>
    );
};

/**
 * To HhMmSs https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
 * @param {*} sec Second
 * @returns HhMmSs
 */
function toHhMmSs(sec) {
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
}

/**
 * Data action of tab
 */
class ActionTab {
    constructor(path) {
        this.path = path;
        this.uid = createUid();

        this.timeActive = 1;
        this.timeInactive = 0;

        this.startActive = new Date();
        this.startInactive = null;
        this.lastActive = null;
        this.lastInactive = null;
    }
}

export default App;
