import React, { useEffect } from "react";
import Footer from "../Footer";
import Account from "../common/Account";
import { useLocation } from "react-router-dom";

function Layout({ children }) {
    const location = useLocation();
    const listNotShowAccount = ["/"];
    useEffect(() => {
        handleResize();
        function handleResize() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <div className="container">
            {!listNotShowAccount.includes(location?.pathname) && <Account />}
            <div className="content">{children}</div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}

export default Layout;
