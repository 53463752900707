import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLevelDetail } from "../services/api/level";

export const getLevelViewDetail = createAsyncThunk(
    "level/getDetail",
    async (params) => {
        const response = await getLevelDetail(params);
        return response;
    }
);

const levelView = createSlice({
    name: "level",
    initialState: {
        data: {},
    },
    reducers: {},
    extraReducers: {
        [getLevelViewDetail.fulfilled]: (state, action) => {
            state.data = action.payload?.data;
        },
    },
});

const { reducer: levelReducer } = levelView;
export default levelReducer;
