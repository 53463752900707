import request from "../request";

export const authApi = async (data) =>
    request({
        url: "/v1/Authorize/SignIn",
        method: "POST",
        data,
        enableFlashMessageSuccess: true,
    });

export const signUpApi = async (data) =>
    request({
        url: "/v1/Authorize/SignUp",
        method: "POST",
        data,
        enableFlashMessageSuccess: true,
    });

export const getMe = async () =>
    request({
        url: "/v1/Authorize/ViewProfile",
        method: "GET",
    });

export const updateProfile = async (data) =>
    request({
        url: "/v1/Authorize/UpdateProfile",
        method: "POST",
        data,
    });

export const changePass = async (data) =>
    request({
        url: "/v1/Authorize/ChangePass",
        method: "POST",
        data,
    });

export const configApi = async () =>
    request({
        url: "/v1/Authorize/GetConfig",
        method: "GET",
    });

export const logout = async (data) =>
    request({
        url: "/v1/Authorize/RevokeToken",
        method: "POST",
        data,
    });

export const forgotPasswordApi = async (data) =>
    request({
        url: "/v1/Authorize/ForgotPass",
        method: "POST",
        data,
        enableFlashMessageSuccess: true,
    });

export const resetPasswordApi = async (data) =>
    request({
        url: "/v1/Authorize/ResetPass",
        method: "POST",
        data,
        enableFlashMessageSuccess: true,
    });
