import request from "../../request";

export const getListDocumentAdminApi = async (data) =>
    request({
        url: "/v1/UserFile/Read",
        method: "PATCH",
        data,
    });

export const uploadDocumentApi = async (data) =>
    request({
        url: "/v1/UserFile/Upload",
        method: "POST",
        data,
    });

export const shareDocument = async (data) =>
    request({
        url: `/v1/SysUser/ShareDocs`,
        method: "POST",
        data,
    });

export const downloadDocumentApi = async (params) =>
    request({
        url: `/v1/UserFile/Download`,
        method: "GET",
        params,
    });

export const deleteDocumentApi = async (data) =>
    request({
        url: `/v1/UserFile/Delete`,
        method: "PATCH",
        data,
    });
