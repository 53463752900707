import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Checkbox, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { signUpApi } from '../../services/api/auth';
import { getListAgent } from "../../stores/ApartmentSlice";
import { ModalLayout } from "./ModalLayout";
import { InputField } from "./../FieldCommon";

const schemaValidate = yup.object().shape({
    firstName: yup.string().required("Please enter first name."),
    lastName: yup.string().required("Please enter first surname."),
    mobilePhone: yup
        .number()
        .required("Please enter mobile number.")
        .typeError("Please enter mobile number."),
    email: yup
        .string()
        .email("Please enter email address correct format.")
        .required("Please enter email address."),
});

export function ModalRegister({ visible, handleCancel, ...rest }) {
    const agents = useSelector((state) => state.apartment.listAgent);
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [check, setCheck] = useState(false);
    const [preMobilePhone, setPreMobilePhone] = useState('');
    const [optionAgents, setOptionAgents] = useState([]);
    const initialValues = {
        firstName: "",
        lastName: "",
        mobilePhone: "",
        email: "",
        agent: null,
    };

    useEffect(() => {
        dispatch(getListAgent());
    }, []);

    useEffect(() => {
        const filterAgents = agents?.filter((item) => item?.value !== "0");
        const options = filterAgents?.map((item) => ({
            label: item?.text,
            value: item?.value,
        }))

        setOptionAgents(options);
    }, [agents]);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(schemaValidate),
    });

    const onSubmit = async (data) => {
        const mobile = `${preMobilePhone ? preMobilePhone : '+61'}${data?.mobilePhone}`;

        const value = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: mobile,
            agentId: data.agent,
        };

        const res = await signUpApi(value);

        if (res.success) {
            setSuccessMessage("Register success!");
            setPreMobilePhone('+61');
            setErrorMessage('');
            reset();
            setTimeout(() => handleCancel(), 1000);
        } else {
            setErrorMessage(res.message);
        }
    };

    return (
        <ModalLayout className="modal-enquire" visible footer={null} handleCancel={handleCancel} {...rest}>
            <div className="modal-enquire__content">
                <h3 className="modal-enquire__title">WELCOME TO </h3>
                <h2 className="modal-enquire__title-main">No1Zoe</h2>
                <p className="modal-enquire__desc">Register user</p>
                <div className="form-enquire">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={20}>
                            <Col span={12}>
                                <InputField
                                    label="First Name*"
                                    register={register}
                                    error={errors}
                                    name="firstName"
                                />
                            </Col>
                            <Col span={12}>
                                <InputField
                                    label="Surname*"
                                    register={register}
                                    error={errors}
                                    name="lastName"
                                />
                            </Col>
                        </Row>
                        <div className="row-model">
                            <input className="area-code-phone" placeholder="+61" value={preMobilePhone} onChange={(e) => setPreMobilePhone(e.target.value)} />
                            <InputField
                                label="Mobile number*"
                                register={register}
                                error={errors}
                                name="mobilePhone"
                            />
                        </div>
                        <Row gutter={20}>
                            <Col span={24}>
                                <InputField
                                    label="Email address*"
                                    register={register}
                                    error={errors}
                                    name="email"
                                />
                            </Col>
                        </Row>
                        {errorMessage && (
                            <p className="errorMessage">{errorMessage}</p>
                        )}

                        <p className="successMessage">{successMessage}</p>
                        <div className="register-link">
                            <div className="register-link__checkbox">
                                <Checkbox className="register-link__checkbox--box" checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                I have read No1ZOE <a href="https://no1zoe.com.au/privacy-policy-disclaimer/" target="_blank" rel="noreferrer">Disclaimer and Privacy Policy</a>
                            </div>
                        </div>
                        <button type="submit" className="btn-send" disabled={!check}>
                            Register
                        </button>
                    </form>
                </div>
            </div>
        </ModalLayout>
    );
}
