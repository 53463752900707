import * as React from "react";

export function InputField({
    label = "",
    error,
    register = () => {},
    name,
    type = "text",
    className,
    placeholder = " ",
    ...rest
}) {
    return (
        <>
            <div className="main-input">
                <div className="content">
                    <input
                        type={type}
                        name={name}
                        className={className}
                        placeholder={placeholder}
                        {...register(name)}
                        {...rest}
                    />
                    {label && <label>{label}</label>}
                </div>
                {error?.[name] && <span>{error?.[name]?.message}</span>}
            </div>
        </>
    );
}
