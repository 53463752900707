import * as cookie from "js-cookie";
import { TOKEN_KEY } from "../utils/common";

// export const getToken = (key = TOKEN_KEY) => {
//     return cookie.get(key);
// };

// export const setToken = (key = TOKEN_KEY, value) => {
//     return cookie.set(key, value);
// };

// export const removeToken = (key = TOKEN_KEY) => {
//     return cookie.remove(key);
// };

export const getToken = (key = TOKEN_KEY) => {
    return localStorage.getItem(key)
};

export const setToken = (key = TOKEN_KEY, value) => {
    return localStorage.setItem(key, value);
};

export const removeToken = (key = TOKEN_KEY) => {
    return localStorage.removeItem(key);
};
