import request from "../request";
import { getConfig, getCookie, createUid, isNull } from "igjs/src/custom";

export const insertLog = async (button, page = null) => {
    let url = "";
    let tabId = getCookie("TabId");

    let cf = getConfig();
    if (isNull(cf)) {
        return;
    }

    if (cf.trackingFrMa) {
        url = `${cf.apiUrl}/api/AnaSessionInfo/InsertLog`;

        let req = {
            insert: true,
            directFromUi: true,
            clientId: getCookie("ClientId"),
            sessionId: getCookie("SessionId"),
            tabId,
        };

        if (cf.debugMode) {
            req.clientId = createUid();
            req.sessionId = createUid();
        }

        request({
            url,
            method: "POST",
            headers: {
                Auth: process.env.REACT_APP_TRACKING_TOKEN,
            },
            data: req,
        });
    } else {
        url = `/Remote/InsertLog?tabId=${tabId}&button=${button}&page=${page}`;

        request({
            url,
            method: "GET",
        });
    }
};
