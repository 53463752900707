import currencyFormatter from "currency-formatter";
import _ from "lodash";

export const revertNameAvatar = (string = "") => {
    const arrString = string?.trim()?.toUpperCase()?.split(" ");
    const firstName = arrString[0]?.charAt(0);
    const lastName =
        arrString.length >= 2
            ? arrString?.[arrString?.length - 1]?.charAt(0)
            : "";
    return firstName + lastName;
};

export const timezoneOffset = () => {
    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset();
    return timezoneOffset;
};

export const compactNumber = (value) => {
    const shortValue = (value / 1000).toFixed(0);
    return shortValue + "k";
};

export const MoneyFormat = (labelValue) => {
    return Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + "B"
        : Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + "M"
        : Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + "K"
        : Math.abs(Number(labelValue));
};

export const drawRect = (x, y, width, height) => {
    return `${x},${y} ${x + width},${y} ${x + width},${y + height} ${x},${
        y + height
    }`;
};

export const moneyFormat = (price) => {
    price = parseFloat(price);
    if (!isNaN(price)) {
        return currencyFormatter.format(price, {
            decimal: ",",
            precision: 0,
        });
    }
    return 0;
};

export const convertMoney = (money) => {
    money = parseFloat(money);
    if (money >= 1000000000) {
        return +(money / 1000000000).toFixed(2) + "B";
    } else if (money >= 1000000) {
        return +(money / 1000000).toFixed(2) + "M";
    } else if (money >= 1000) {
        return +(money / 1000).toFixed(2) + "K";
    } else if (money <= -1000000000) {
        return +(money / 1000000000).toFixed(2) + "B";
    } else if (money <= -1000000) {
        return +(money / 1000000).toFixed(2) + "M";
    } else if (money <= -1000) {
        return +(money / 1000000).toFixed(2) + "K";
    } else {
        return moneyFormat(money);
    }
};

export const convertObjectToFormData = (
    obj,
    formData = new FormData(),
    parentKey,
) => {
    Object.entries(obj).forEach(([key, value]) => {
        const propName = parentKey ? `${parentKey}[${key}]` : key;

        if (Array.isArray(value)) {
            if (value.length > 0)
                convertObjectToFormData(value, formData, propName);
        } else if (
            _.isPlainObject(value) &&
            !(value instanceof File) &&
            !(value instanceof Blob)
        ) {
            convertObjectToFormData(value, formData, propName);
        } else {
            formData.append(propName, value);
        }
    });

    return formData;
};
