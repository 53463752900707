import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getListDocumentAdminApi
} from "../../../services/api/admin/document";

export const getListDocumentAdmin = createAsyncThunk(
    "document/GET_LIST",
    async (data) => {
        const response = await getListDocumentAdminApi(data);
        return response;
    }
);

const document = createSlice({
    name: "document",
    initialState: {
        listDocument: [],
        loading: false,
    },
    extraReducers: {
        [getListDocumentAdmin.pending]: (state) => {
            state.loading = true;
        },
        [getListDocumentAdmin.rejected]: (state) => {
            state.loading = false;
        },
        [getListDocumentAdmin.fulfilled]: (state, action) => {
            state.loading = false;
            state.listDocument = action.payload.data;
        },
    },
});

const { reducer: documentReducer } = document;
export default documentReducer;
