import request from "../request";

export const getApartmentDetail = async (params) =>
    request({
        url: `v1/Apartment/Read/0/${params}`,
        method: "GET",
    });

export const searchApi = async () =>
    request({
        url: "/v1/Apartment/SearchCriteria",
        method: "GET",
    });

export const filterApi = async (data) =>
    request({
        url: "/v1/Apartment/Read",
        method: "PATCH",
        data,
    });

export const getAvailability = async () =>
    request({
        url: "/v1/FloorLevel/Availability",
        method: "GET",
    });

export const getUpdateStatus = async (params) =>
    request({
        url: `/v1/Apartment/UpdateStatus/${params}`,
        method: "GET",
    });

export const updateStatus = async (data) =>
    request({
        url: `/v1/Apartment/UpdateStatus`,
        method: "POST",
        data,
    });

export const addFavorite = async (id) =>
    request({
        url: `/v1/Apartment/Favourite/${id}/true`,
        method: "GET",
    });

export const addEnquire = async (data) =>
    request({
        url: `/v1/Enquire/Create`,
        method: "POST",
        data,
    });

export const addShareApartment = async (data) =>
    request({
        url: `/v1/Apartment/ShareListing`,
        method: "POST",
        data,
    });

export const getUserShared = async () =>
    request({
        url: `/v1/Apartment/ListUserShare`,
        method: "GET",
    });

export const getFavoriteListApi = async (data) =>
    request({
        url: "/v1/Apartment/GetFavourites",
        method: "PATCH",
        data,
    });

export const removeFavorite = async (id) =>
    request({
        url: `/v1/Apartment/Favourite/${id}/false`,
        method: "GET",
    });

export const getListAgentApi = async () =>
    request({
        url: `/v1/SysUser/Picklist`,
        method: "GET",
    });
