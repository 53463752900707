import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "./user";
import levelReducer from "./level";
import apartmentReducer from "./ApartmentSlice";
import documentReducer from "./admin/DocumentSlice";
const reducer = combineReducers({
    // Add reducer
    user: userReducer,
    apartment: apartmentReducer,
    level: levelReducer,
    document: documentReducer,
});
const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
export default store;
