import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
    getApartmentDetail,
    searchApi,
    filterApi,
    getAvailability,
    getUpdateStatus,
    updateStatus,
    getFavoriteListApi,
    getUserShared,
    getListAgentApi,
} from "../../services/api/apartment";

export const getApartmentDetailAction = createAsyncThunk("apartment/DETAIL", async (params) => {
    const response = await getApartmentDetail(params);
    return response;
});

export const searchCriteriaAction = createAsyncThunk("apartment/search", async () => {
    const response = await searchApi();
    return response;
});

export const filterCriteriaAction = createAsyncThunk("apartment/filter", async (params) => {
    const response = await filterApi(params);
    return response;
});

export const getAvailabilityAction = createAsyncThunk("apartment/availability", async () => {
    const response = await getAvailability();
    return response;
});

export const getUpdateStatusAction = createAsyncThunk("apartment/status", async (params) => {
    const response = await getUpdateStatus(params);
    return response;
});

export const updateStatusAction = createAsyncThunk("apartment/updateStatus", async (params) => {
    const response = await updateStatus(params);
    return response;
});

export const updateRouterAction = createAsyncThunk("apartment/update-router", async (params) => {
    return params;
});

export const getFavoriteList = createAsyncThunk("apartment/FAVORITE", async (data) => {
    const response = await getFavoriteListApi(data);
    return response;
});

export const getUserSharedList = createAsyncThunk("apartment/USER", async () => {
    const response = await getUserShared();
    return response;
});

export const getListAgent = createAsyncThunk("apartment/AGENT", async () => {
    const response = await getListAgentApi();
    return response;
});

const apartment = createSlice({
    name: "apartment",
    initialState: {
        listAgent: [],
        listUser: [],
        listFavorite: {},
        data_detail: {},
        success: false,
        message: "",
        data: {},
        loading: false,
        error: {},
        dataFilter: {},
        data_availability: {},
        dataStatus: {},
        router: "",
        isShowAvailability: false,
    },
    reducers: {
        showAvailability: (state) => {
            state.isShowAvailability = true;
        },
        hideAvailability: (state) => {
            state.isShowAvailability = false;
        },
    },
    extraReducers: {
        [getApartmentDetailAction.rejected]: (state, action) => {
            state.success = action.payload.success;
        },
        [getApartmentDetailAction.fulfilled]: (state, action) => {
            state.success = action.payload.success;
            state.data_detail = action.payload.data;
        },

        [searchCriteriaAction.pending]: (state) => {
            state.loading = true;
        },
        [searchCriteriaAction.rejected]: (state, action) => {
            state.loading = true;
            state.error = action.payload;
        },
        [searchCriteriaAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },

        [filterCriteriaAction.pending]: (state) => {
            state.loading = true;
        },
        [filterCriteriaAction.rejected]: (state, action) => {
            state.loading = true;
            state.error = action.payload;
        },
        [filterCriteriaAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataFilter = action.payload;
        },

        [getAvailabilityAction.pending]: (state) => {
            state.loading = true;
        },
        [getAvailabilityAction.rejected]: (state) => {
            state.loading = false;
        },
        [getAvailabilityAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.data_availability = action.payload.data;
        },

        [getUpdateStatusAction.pending]: (state) => {
            state.loading = true;
        },
        [getUpdateStatusAction.rejected]: (state) => {
            state.loading = false;
        },
        [getUpdateStatusAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataStatus = action.payload.data;
        },

        [updateStatusAction.pending]: (state) => {
            state.loading = true;
        },
        [updateStatusAction.rejected]: (state) => {
            state.loading = false;
        },
        [updateStatusAction.fulfilled]: (state, action) => {
            state.loading = false;
        },

        [updateRouterAction.pending]: (state) => {
            state.loading = true;
        },
        [updateRouterAction.rejected]: (state) => {
            state.loading = false;
        },
        [updateRouterAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.router = action.payload;
        },

        [getFavoriteList.pending]: (state) => {
            state.loading = true;
        },
        [getFavoriteList.rejected]: (state) => {
            state.loading = false;
        },
        [getFavoriteList.fulfilled]: (state, action) => {
            state.loading = false;
            state.listFavorite = action.payload;
        },

        [getUserSharedList.pending]: (state) => {
            state.loading = true;
        },
        [getUserSharedList.rejected]: (state) => {
            state.loading = false;
        },
        [getUserSharedList.fulfilled]: (state, action) => {
            state.loading = false;
            state.listUser = action.payload.data;
        },

        [getListAgent.pending]: (state) => {
            state.loading = true;
        },
        [getListAgent.rejected]: (state) => {
            state.loading = false;
        },
        [getListAgent.fulfilled]: (state, action) => {
            state.loading = false;
            state.listAgent = action.payload.data.agents;
        },
    },
});
export const { showAvailability, hideAvailability } = apartment.actions;
const { reducer: apartmentReducer } = apartment;
export default apartmentReducer;
