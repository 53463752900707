import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import AdminLayout from "../components/AdminLayout";
import LoadingSpinner from "../components/common/LoadingSpinner";

function AuthRoute({ component: Component, ...rest }) {
    const authentication = useSelector((state) => state.user.authentication);
    const isFirstAccess = useSelector((state) => state.user.isFirstAccess);
    const loading = useSelector((state) => state.user.loading);
    const history = useHistory();

    useLayoutEffect(() => {
        if (!authentication && !isFirstAccess) history.push("/");
    }, [authentication, history, isFirstAccess]);

    if (loading) return <LoadingSpinner />;

    return (
        <Route {...rest}>
            <AdminLayout>
                <Component />
            </AdminLayout>
        </Route>
    );
}

export default AuthRoute;
