import axios from "axios";
import queryString from "query-string";
import { API_SERVER } from "../constants";
import { getToken, setToken } from "../helpers/tokenMain";
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "../utils/common";
const urlApiMain = API_SERVER;

const baseApiConfig = {
    baseURL: `${urlApiMain}`,
    headers: {
        "content-type": "application/json",
    },
    timeout: 60000, // 60s
    paramsSerializer: (params) => queryString.stringify(params),
};

const baseApiClient = axios.create(baseApiConfig);

baseApiClient.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (error) {
        const originalRequest = error.config;
        if (typeof error === "undefined") {
            // request cancelled
            // when backend server is not available at all
        } else if (typeof error.response === "undefined") {
            // when request is timeout
        } else if (error?.response?.status === 401) {
            // apply refresh token logic here instead of redirecting to login
            let accessToken = getToken();
            let refreshToken = getToken(REFRESH_TOKEN_KEY);
            if (accessToken && refreshToken) {
                baseApiClient.defaults.withCredentials = true;
                baseApiClient
                    .get("/v1/Authorize/RefreshToken")
                    .then((res) => {
                        if (res?.data?.accessToken && res?.data?.refreshToken) {
                            setToken(TOKEN_KEY, res?.data?.accessToken);
                            setToken(
                                REFRESH_TOKEN_KEY,
                                res?.data?.refreshToken
                            );
                        }
                    })
                    .then(() => {
                        originalRequest.headers.Authorization = `Bearer ${getToken()}`;
                        return axios(originalRequest);
                    });
            }
        }
        return Promise.reject(error);
    }
);

const request = ({
    enableFlashMessageError = true,
    enableFlashMessageSuccess = false,
    isAuth = true,
    ...options
}) => {
    if (isAuth) {
        const accessToken = getToken();
        baseApiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    const onSuccess = (response) => {
        return response;
    };

    return baseApiClient(options).then(onSuccess);
};

export default request;
