import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

function Footer() {
    const [showList, setShowList] = useState(false);
    const menus = [
        {
            title: "Location",
            url: "/location",
        },
        {
            title: "Lifestyle",
            url: "/lifestyle",
        },
        {
            title: "Apartment",
            url: "/apartments",
        },
        {
            title: "Design",
            url: "/design",
        },
        {
            title: "Team",
            url: "/team",
        },
        {
            title: "Media",
            url: "/library",
        }
    ];

    const leftMenus = [
        {
            title: "Location",
            url: "/location",
        },
        {
            title: "Lifestyle",
            url: "/lifestyle",
        },
        {
            title: "Apartment",
            url: "/apartments",
        },
    ];
    const rightMenus = [
        {
            title: "Design",
            url: "/design",
        },
        {
            title: "Team",
            url: "/team",
        },
        {
            title: "Media",
            url: "/library",
        },
    ];

    const handleOnClick = () => {
        setShowList(!showList);
    }

    return (
        <>
            <div className="section-footer">
                <ul className="section-footer__nav-left">
                    {leftMenus &&
                        leftMenus.map((menu, index) => (
                            <li key={index.toString()}>
                                <NavLink
                                    to={menu.url}
                                    activeClassName="list-active"
                                >
                                    {menu.title}
                                </NavLink>
                            </li>
                        ))}
                </ul>
                <a href="https://no1zoe.com.au/" target="_blank" className="section-footer__logo-link">
                    <div className="section-footer__main-logo">
                        <img
                            className="section-footer__logo"
                            src={`${process.env.REACT_APP_MEDIA}/media/user/icons/zoe-logo.svg`}
                            alt=""
                        />
                    </div>
                </a>
                <ul className="section-footer__nav-right">
                    {rightMenus &&
                        rightMenus.map((menu, index) => (
                            <li key={index.toString()} >
                                <NavLink
                                    to={menu.url}
                                    activeClassName="list-active"
                                    onClick={handleOnClick}
                                >
                                    {menu.title}
                                </NavLink>
                            </li>
                        ))}
                </ul>
            </div>
            <div
                className={`mobile-footer ${showList && "mobile-footer--showList"
                    }`}
            >
                <div className="mobile-footer__control">
                    {showList ? (
                        <img
                            onClick={() => setShowList(!showList)}
                            className="control__icon"
                            src={`${process.env.REACT_APP_MEDIA}/media/images/icons/close-list-mobile-menu.svg`}
                            alt=""
                        />
                    ) : (
                        <img
                            onClick={() => setShowList(!showList)}
                            className="control__icon"
                            src={`${process.env.REACT_APP_MEDIA}/media/images/icons/open-list-mobile-menu.svg`}
                            alt=""
                        />
                    )}

                    <a href="https://no1zoe.com.au/" target="_blank">
                        <img
                            className="control__logo"
                            src={`${process.env.REACT_APP_MEDIA}/media/user/icons/zoe-logo.svg`}
                            alt=""
                        />
                    </a>
                </div>
                <ul className="mobile-footer__list">
                    {menus &&
                        menus.map((menu, index) => (
                            <ul key={index} className="mobile-footer__item">
                                <NavLink
                                    to={menu.url}
                                    activeClassName="list-active"
                                    onClick={handleOnClick}
                                >
                                    {menu.title}
                                </NavLink>
                            </ul>
                        ))}
                </ul>
            </div>
        </>
    );
}

export default Footer;
