export const data = [
    {
        id: "1",
        icon: `${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-menu-info.svg`,
        desc: "My Details",
        link: "/admin/my-details",
    },
    {
        id: "2",
        icon: `${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-menu-favourite.svg`,
        desc: "Favourites",
        link: "/admin/favourites",
    },
    {
        id: "3",
        icon: `${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-menu-document.svg`,
        desc: "Documents",
        link: "/admin/documents",
    },
    {
        id: "4",
        icon: `${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-menu-setting.svg`,
        desc: "Settings",
        link: "/admin/settings",
    },
];

export const masterSuperData = [
    {
        id: "1",
        icon: `${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-menu-info.svg`,
        desc: "My Details",
        link: "/admin/my-details",
    },
    {
        id: "2",
        icon: `${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-menu-document.svg`,
        desc: "Documents",
        link: "/admin/documents",
    },
    {
        id: "3",
        icon: `${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-menu-setting.svg`,
        desc: "Settings",
        link: "/admin/settings",
    },
];
