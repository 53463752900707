import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { data, masterSuperData } from "./mock-data";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

const { Sider, Content } = Layout;

const AdminLayout = ({ children }) => {
    const location = useLocation();

    const dataUser = useSelector((state) => state.user.currentUser.fullName);
    const currentUser = useSelector((state) => state.user.currentUser);

    const [isShowMenu, setIsShowMenu] = useState(false);
    const [activeLink, setActiveLink] = useState(null);

    const style = {
        margin: "0 auto",
        border: "none",
        position: "relative",
    };

    useEffect(() => {
        data.map((item) => {
            if (item.link === location.pathname) {
                setActiveLink(item.id);
            }
        });
    }, []);

    const handleShowMenu = () => {
        setIsShowMenu(!isShowMenu);
    }

    useEffect(() => {
        setIsShowMenu(false);
    }, [location.pathname])

    return (
        <div className="account-layout">
            {activeLink && (
                <Row>
                    <Col
                        trigger={null}
                        collapsible
                        style={{
                            backgroundColor: "white",
                            boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                            minHeight: "100vh",
                            borderRight: "1px solid #E4E4E4",
                        }}
                        span={4}
                        className={`account-layout__menu ${isShowMenu && 'show'}`}
                    >
                        <div className="account-layout__logo">
                            <img
                                src={`${process.env.REACT_APP_MEDIA}/media/admin/icon/logo.svg`}
                                alt=""
                            />
                        </div>
                        <Menu style={style} defaultSelectedKeys={[activeLink]}>
                            {(currentUser?.isMaster || currentUser?.isSuper ? masterSuperData : data).map((item) => (
                                <Menu.Item
                                    key={item.id}
                                    icon={<img src={item.icon} alt="img" />}
                                    className="item"
                                >
                                    <Link to={item.link}>
                                        <p>{item.desc}</p>
                                    </Link>
                                </Menu.Item>
                            ))}
                        </Menu>
                        <div className="show-menu" onClick={handleShowMenu}>
                            <img
                                src={`${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-${isShowMenu ? 'close' : 'menu'}.svg`}
                                alt=""
                            />
                        </div>
                    </Col>
                    <Col span={20} className="account-layout__content">
                        <div className="account-layout__header">
                            <div className="account-layout__header__wrap">
                                <h4>Welcome, <span>{dataUser}</span></h4>
                                <div className="account-layout__header__right">
                                    <Link to="/home">
                                        <div className="account-layout__header__back">
                                            <img
                                                src={`${process.env.REACT_APP_MEDIA}/media/admin/icon/icon-back.svg`}
                                                alt=""
                                            />
                                        </div>
                                        Back to Homepage
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <Content style={{ backgroundColor: "white" }}>
                            {children}
                        </Content>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default AdminLayout;
