import * as React from "react";

export function TextareaField({
    label,
    error,
    register = () => {},
    name,
    type = "text",
    className,
    placeholder = "",
    ...rest
}) {
    return (
        <div className="main-textarea">
            {label && <label>{label}</label>}
            <textarea
                name={name}
                className={className}
                placeholder={placeholder}
                {...register(name)}
                {...rest}
            />
            {error?.[name] && <span>{error?.[name]?.message}</span>}
        </div>
    );
}
